<!-- 弹框 -->
<template>
  <div class="home">
    <el-dialog
      v-dialogDrag
      :title="dialogTit"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      append-to-body
    >
      <div>
        <!-- 条件 -->
        <div class="common-screen-container">
          <div
            class="common-input-container"
            v-for="(item, i) in paramsTit"
            :key="i"
          >
            <span>{{ item.name }}:</span>
            <el-input
              v-if="item.type == 'input'"
              v-model="headerParams[item.modelKey]"
              placeholder="请输入内容"
              class="common-screen-input"
              clearable
            ></el-input>
            <el-select
              v-else-if="item.type == 'select'"
              class="common-screen-input"
              @change="commonFun()"
              v-model="headerParams[item.modelKey]"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item2 in (item.selOpt)"
                :key="item2.id"
                :label="item2.name"
                :value="item2.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            v-show="paramsTit.length"
            class="common-screen-btn"
            type="primary"
            @click="
              () => {
                getHeaderList();
              }
            "
            >查 询</el-button
          >
          <el-button
            v-show="paramsTit.length"
            class="common-screen-btn"
            plain
            @click="() => resetBtn()"
            >重 置</el-button
          >
        </div>
        <!-- 列表 -->
        <el-table
           v-loading="loading" element-loading-text="数据较多，拼命加载中..."
          :data="headerTableData"
          style="width: 100%"
        >
          <el-table-column
            v-for="(item, i) in childColName"
            :key="i"
            :label="item.name"
            :formatter="tableColumn"
          >
            <template slot-scope="scope">
              <span v-if="item.colProp && item.colProp.length == 2"
                ><span>{{ scope.row[item.colProp[0]] }}</span
                ><br />
                <span>{{ scope.row[item.colProp[1]] }}</span></span
              >
              <span v-else>
                <span
                  v-if="scope.row[item.colProp[0]] == 'servicing'"
                  class="common-tableStatus-green"
                  >正在服务</span
                >
                <span
                  v-else-if="scope.row[item.colProp[0]] == 'stop'"
                  class="common-tableStatus-red"
                  >服务终止</span
                >
                <span class="table-btn" v-else-if="item.url">{{ scope.row[item.colProp[0]] || "--" }}</span>
                <span v-else>{{ scope.row[item.colProp[0]] || "--" }}</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="(e) => pageFun(e, 'pageSize')"
          @current-change="(e) => pageFun(e)"
          style="text-align: center"
          :page-sizes="pagination.pageSizes"
          :page-size="headerParams.pageSize"
          :current-page="headerParams.page"
          layout="total, prev, pager, next"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用

import { Config, tableColumn } from "../../../utils/index.js";

export default {
  name: "",
  components: {},
  props: [
    "methodFuc",
    "comId",
    "dialogTit",
    "dialogVisible",
    "headerType",
    "paramsTit",
    "childColName"
  ],
  data() {
    return {
      Config,
      tableColumn,
      headerParams: {
        pageNum: 1,
        pageSize: 10
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      // loading: this.loading2,
      loading: false,
      headerTableData: []
    };
  },
  //监听属性 类似于data概念
  computed: {
    // ...mapState(["getUserInfo"])
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleClose() {
      this.$emit("handleClose");
      this.headerTableData = [];
      this.resetBtn("noupdate");
    },
    commonFun() {
      this.headerParams.pageSize = 10;
      this.headerParams.page = 1;
      this.getHeaderList();
    },
    async getHeaderList(a) {
      this.loading = true;

      let params = { ...this.headerParams };
      params.companyId = Number(this.comId);
      if (a) {
        params.type = a;
      } else {
        params.type = this.headerType;
      }
      console.log(params, "头部请求参数！！！！！！！");
      const { data } = await this.methodFuc(params);
      console.log(data, "头部列必报");
      if (data && data.list && data.list.length) {
        this.headerTableData = data.list;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.size;
      } else {
        this.headerTableData = [];
        this.pagination.total = 0;
        // this.pagination.pageSize = data.size;
      }

      this.loading = false;
    },
    resetBtn(type) {
      this.headerParams = {
        page: 1,
        pageSize: 10
      };
      // 判断获取
      if (!type) {
        this.getHeaderList();
      }
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.headerParams.pageSize = e;
      } else {
        this.headerParams.page = e;
      }

      this.getHeaderList();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.table-btn {
  color: #1890ff;
  cursor: pointer;
}
</style>
